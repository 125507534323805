import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _6b4f6348 = () => interopDefault(import('../pages/checkout.vue' /* webpackChunkName: "pages/checkout" */))
const _4de66c81 = () => interopDefault(import('../pages/clinic/index.vue' /* webpackChunkName: "pages/clinic/index" */))
const _3492d952 = () => interopDefault(import('../pages/fb.vue' /* webpackChunkName: "pages/fb" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _4d8cf30c = () => interopDefault(import('../pages/shop.vue' /* webpackChunkName: "pages/shop" */))
const _aedca4e8 = () => interopDefault(import('../pages/my-favorites.vue' /* webpackChunkName: "pages/my-favorites" */))
const _6593263f = () => interopDefault(import('../pages/my-products.vue' /* webpackChunkName: "pages/my-products" */))
const _210efe22 = () => interopDefault(import('../pages/my-watchlist.vue' /* webpackChunkName: "pages/my-watchlist" */))
const _d2c8792c = () => interopDefault(import('../pages/my-page.vue' /* webpackChunkName: "pages/my-page" */))
const _e8ad238a = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _7c5595a7 = () => interopDefault(import('../pages/recipe/index.vue' /* webpackChunkName: "pages/recipe/index" */))
const _5cb6cfa9 = () => interopDefault(import('../pages/reset.vue' /* webpackChunkName: "pages/reset" */))
const _584b7a50 = () => interopDefault(import('../pages/results.vue' /* webpackChunkName: "pages/results" */))
const _fb82fea4 = () => interopDefault(import('../pages/signup.vue' /* webpackChunkName: "pages/signup" */))
const _f0430588 = () => interopDefault(import('../pages/testimonial/index.vue' /* webpackChunkName: "pages/testimonial/index" */))
const _72be0234 = () => interopDefault(import('../pages/recipe/cat/_slug.vue' /* webpackChunkName: "pages/recipe/cat/_slug" */))
const _4c2fcb39 = () => interopDefault(import('../pages/clinic/_slug.vue' /* webpackChunkName: "pages/clinic/_slug" */))
const _387baf62 = () => interopDefault(import('../pages/category/_slug.vue' /* webpackChunkName: "pages/category/_slug" */))
const _2981470e = () => interopDefault(import('../pages/order-confirm/_hash.vue' /* webpackChunkName: "pages/order-confirm/_hash" */))
const _046f4fed = () => interopDefault(import('../pages/product-category/_slug.vue' /* webpackChunkName: "pages/product-category/_slug" */))
const _4e9aa8d1 = () => interopDefault(import('../pages/product-tag/_slug.vue' /* webpackChunkName: "pages/product-tag/_slug" */))
const _321e5d78 = () => interopDefault(import('../pages/product/_slug.vue' /* webpackChunkName: "pages/product/_slug" */))
const _7a9ef45f = () => interopDefault(import('../pages/recipe/_slug.vue' /* webpackChunkName: "pages/recipe/_slug" */))
const _f3b04818 = () => interopDefault(import('../pages/testimonial/_slug.vue' /* webpackChunkName: "pages/testimonial/_slug" */))
const _5a8dc39c = () => interopDefault(import('../pages/reset-password/_token/_email.vue' /* webpackChunkName: "pages/reset-password/_token/_email" */))
const _275b8fde = () => interopDefault(import('../pages/review/_order/_hash.vue' /* webpackChunkName: "pages/review/_order/_hash" */))
const _9b847f38 = () => interopDefault(import('../pages/_slug.vue' /* webpackChunkName: "pages/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _6b4f6348,
    name: "checkout___pt"
  }, {
    path: "/clinicas-lev",
    component: _4de66c81,
    name: "clinic___pt"
  }, {
    path: "/fb",
    component: _3492d952,
    name: "fb___pt"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login___pt"
  }, {
    path: "/loja-online",
    component: _4d8cf30c,
    name: "shop___pt"
  }, {
    path: "/meus-favoritos",
    component: _aedca4e8,
    name: "my-favorites___pt"
  }, {
    path: "/meus-produtos",
    component: _6593263f,
    name: "my-products___pt"
  }, {
    path: "/minha-lista-de-observacao",
    component: _210efe22,
    name: "my-watchlist___pt"
  }, {
    path: "/minha-pagina",
    component: _d2c8792c,
    name: "my-page___pt"
  }, {
    path: "/nutricao",
    component: _e8ad238a,
    name: "blog___pt"
  }, {
    path: "/receitas",
    component: _7c5595a7,
    name: "recipe___pt"
  }, {
    path: "/reset",
    component: _5cb6cfa9,
    name: "reset___pt"
  }, {
    path: "/resultados-pesquisa",
    component: _584b7a50,
    name: "results___pt"
  }, {
    path: "/signup",
    component: _fb82fea4,
    name: "signup___pt"
  }, {
    path: "/testemunhos",
    component: _f0430588,
    name: "testimonial___pt"
  }, {
    path: "/en/checkout",
    component: _6b4f6348,
    name: "checkout___en"
  }, {
    path: "/en/fb",
    component: _3492d952,
    name: "fb___en"
  }, {
    path: "/en/lev-clinics",
    component: _4de66c81,
    name: "clinic___en"
  }, {
    path: "/en/login",
    component: _7d9c04c3,
    name: "login___en"
  }, {
    path: "/en/my-favorites",
    component: _aedca4e8,
    name: "my-favorites___en"
  }, {
    path: "/en/my-page",
    component: _d2c8792c,
    name: "my-page___en"
  }, {
    path: "/en/my-products",
    component: _6593263f,
    name: "my-products___en"
  }, {
    path: "/en/my-watchlist",
    component: _210efe22,
    name: "my-watchlist___en"
  }, {
    path: "/en/nutrition",
    component: _e8ad238a,
    name: "blog___en"
  }, {
    path: "/en/recipes",
    component: _7c5595a7,
    name: "recipe___en"
  }, {
    path: "/en/reset",
    component: _5cb6cfa9,
    name: "reset___en"
  }, {
    path: "/en/results",
    component: _584b7a50,
    name: "results___en"
  }, {
    path: "/en/shop",
    component: _4d8cf30c,
    name: "shop___en"
  }, {
    path: "/en/signup",
    component: _fb82fea4,
    name: "signup___en"
  }, {
    path: "/en/testimonials",
    component: _f0430588,
    name: "testimonial___en"
  }, {
    path: "/es/centros-lev",
    component: _4de66c81,
    name: "clinic___es"
  }, {
    path: "/es/checkout",
    component: _6b4f6348,
    name: "checkout___es"
  }, {
    path: "/es/fb",
    component: _3492d952,
    name: "fb___es"
  }, {
    path: "/es/login",
    component: _7d9c04c3,
    name: "login___es"
  }, {
    path: "/es/mi-lista-de-seguimiento",
    component: _210efe22,
    name: "my-watchlist___es"
  }, {
    path: "/es/mi-pagina",
    component: _d2c8792c,
    name: "my-page___es"
  }, {
    path: "/es/mis-favoritos",
    component: _aedca4e8,
    name: "my-favorites___es"
  }, {
    path: "/es/mis-productos",
    component: _6593263f,
    name: "my-products___es"
  }, {
    path: "/es/nutricion",
    component: _e8ad238a,
    name: "blog___es"
  }, {
    path: "/es/recetas",
    component: _7c5595a7,
    name: "recipe___es"
  }, {
    path: "/es/reset",
    component: _5cb6cfa9,
    name: "reset___es"
  }, {
    path: "/es/resultados-busqueda",
    component: _584b7a50,
    name: "results___es"
  }, {
    path: "/es/signup",
    component: _fb82fea4,
    name: "signup___es"
  }, {
    path: "/es/testimonios",
    component: _f0430588,
    name: "testimonial___es"
  }, {
    path: "/es/tienda",
    component: _4d8cf30c,
    name: "shop___es"
  }, {
    path: "/fr/boutique",
    component: _4d8cf30c,
    name: "shop___fr"
  }, {
    path: "/fr/centre-lev-diet",
    component: _4de66c81,
    name: "clinic___fr"
  }, {
    path: "/fr/checkout",
    component: _6b4f6348,
    name: "checkout___fr"
  }, {
    path: "/fr/fb",
    component: _3492d952,
    name: "fb___fr"
  }, {
    path: "/fr/login",
    component: _7d9c04c3,
    name: "login___fr"
  }, {
    path: "/fr/ma-liste-de-surveillance",
    component: _210efe22,
    name: "my-watchlist___fr"
  }, {
    path: "/fr/ma-page",
    component: _d2c8792c,
    name: "my-page___fr"
  }, {
    path: "/fr/mes-favoris",
    component: _aedca4e8,
    name: "my-favorites___fr"
  }, {
    path: "/fr/mes-produits",
    component: _6593263f,
    name: "my-products___fr"
  }, {
    path: "/fr/nutrition",
    component: _e8ad238a,
    name: "blog___fr"
  }, {
    path: "/fr/recettes",
    component: _7c5595a7,
    name: "recipe___fr"
  }, {
    path: "/fr/reset",
    component: _5cb6cfa9,
    name: "reset___fr"
  }, {
    path: "/fr/resultats",
    component: _584b7a50,
    name: "results___fr"
  }, {
    path: "/fr/signup",
    component: _fb82fea4,
    name: "signup___fr"
  }, {
    path: "/fr/temoignages",
    component: _f0430588,
    name: "testimonial___fr"
  }, {
    path: "/en/recipes/category/:slug",
    component: _72be0234,
    name: "recipe-cat-slug___en"
  }, {
    path: "/es/recetas/categoria/:slug",
    component: _72be0234,
    name: "recipe-cat-slug___es"
  }, {
    path: "/fr/recettes/categorie/:slug",
    component: _72be0234,
    name: "recipe-cat-slug___fr"
  }, {
    path: "/en/lev-clinics/:slug",
    component: _4c2fcb39,
    name: "clinic-slug___en"
  }, {
    path: "/en/nutrition-category/:slug",
    component: _387baf62,
    name: "category-slug___en"
  }, {
    path: "/en/order-confirm/:hash?",
    component: _2981470e,
    name: "order-confirm-hash___en"
  }, {
    path: "/en/product-category/:slug",
    component: _046f4fed,
    name: "product-category-slug___en"
  }, {
    path: "/en/product-tag/:slug",
    component: _4e9aa8d1,
    name: "product-tag-slug___en"
  }, {
    path: "/en/product/:slug",
    component: _321e5d78,
    name: "product-slug___en"
  }, {
    path: "/en/recipes/:slug",
    component: _7a9ef45f,
    name: "recipe-slug___en"
  }, {
    path: "/en/testimonials/:slug",
    component: _f3b04818,
    name: "testimonial-slug___en"
  }, {
    path: "/es/categoria-producto/:slug",
    component: _046f4fed,
    name: "product-category-slug___es"
  }, {
    path: "/es/centros-lev/:slug",
    component: _4c2fcb39,
    name: "clinic-slug___es"
  }, {
    path: "/es/etiqueta-producto/:slug",
    component: _4e9aa8d1,
    name: "product-tag-slug___es"
  }, {
    path: "/es/nutricion-categoria/:slug",
    component: _387baf62,
    name: "category-slug___es"
  }, {
    path: "/es/order-confirm/:hash?",
    component: _2981470e,
    name: "order-confirm-hash___es"
  }, {
    path: "/es/producto/:slug",
    component: _321e5d78,
    name: "product-slug___es"
  }, {
    path: "/es/recetas/:slug",
    component: _7a9ef45f,
    name: "recipe-slug___es"
  }, {
    path: "/es/testimonios/:slug",
    component: _f3b04818,
    name: "testimonial-slug___es"
  }, {
    path: "/fr/categorie-produit/:slug",
    component: _046f4fed,
    name: "product-category-slug___fr"
  }, {
    path: "/fr/categorie/:slug",
    component: _387baf62,
    name: "category-slug___fr"
  }, {
    path: "/fr/centre-lev-diet/:slug",
    component: _4c2fcb39,
    name: "clinic-slug___fr"
  }, {
    path: "/fr/etiquette-produit/:slug",
    component: _4e9aa8d1,
    name: "product-tag-slug___fr"
  }, {
    path: "/fr/order-confirm/:hash?",
    component: _2981470e,
    name: "order-confirm-hash___fr"
  }, {
    path: "/fr/produit/:slug",
    component: _321e5d78,
    name: "product-slug___fr"
  }, {
    path: "/fr/recettes/:slug",
    component: _7a9ef45f,
    name: "recipe-slug___fr"
  }, {
    path: "/fr/temoignages/:slug",
    component: _f3b04818,
    name: "testimonial-slug___fr"
  }, {
    path: "/receitas/categoria/:slug",
    component: _72be0234,
    name: "recipe-cat-slug___pt"
  }, {
    path: "/en/reset-password/:token?/:email?",
    component: _5a8dc39c,
    name: "reset-password-token-email___en"
  }, {
    path: "/en/review/:order?/:hash?",
    component: _275b8fde,
    name: "review-order-hash___en"
  }, {
    path: "/es/reset-password/:token?/:email?",
    component: _5a8dc39c,
    name: "reset-password-token-email___es"
  }, {
    path: "/es/review/:order?/:hash?",
    component: _275b8fde,
    name: "review-order-hash___es"
  }, {
    path: "/fr/reset-password/:token?/:email?",
    component: _5a8dc39c,
    name: "reset-password-token-email___fr"
  }, {
    path: "/fr/review/:order?/:hash?",
    component: _275b8fde,
    name: "review-order-hash___fr"
  }, {
    path: "/categoria-produto/:slug",
    component: _046f4fed,
    name: "product-category-slug___pt"
  }, {
    path: "/clinicas-lev/:slug",
    component: _4c2fcb39,
    name: "clinic-slug___pt"
  }, {
    path: "/en/:slug?",
    component: _9b847f38,
    name: "slug___en"
  }, {
    path: "/es/:slug?",
    component: _9b847f38,
    name: "slug___es"
  }, {
    path: "/etiqueta-produto/:slug",
    component: _4e9aa8d1,
    name: "product-tag-slug___pt"
  }, {
    path: "/fr/:slug?",
    component: _9b847f38,
    name: "slug___fr"
  }, {
    path: "/nutricao-categoria/:slug",
    component: _387baf62,
    name: "category-slug___pt"
  }, {
    path: "/order-confirm/:hash?",
    component: _2981470e,
    name: "order-confirm-hash___pt"
  }, {
    path: "/produto/:slug",
    component: _321e5d78,
    name: "product-slug___pt"
  }, {
    path: "/recetas/:slug",
    component: _7a9ef45f,
    name: "recipe-slug___pt"
  }, {
    path: "/testemunhos/:slug",
    component: _f3b04818,
    name: "testimonial-slug___pt"
  }, {
    path: "/reset-password/:token?/:email?",
    component: _5a8dc39c,
    name: "reset-password-token-email___pt"
  }, {
    path: "/review/:order?/:hash?",
    component: _275b8fde,
    name: "review-order-hash___pt"
  }, {
    path: "/:slug?",
    component: _9b847f38,
    name: "slug___pt"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
